import ActionTypes from '../actions/types'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  voucherCode: '',
  birthYear: null,
  gender: ''
}

const signupFormValue = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SIGNUP_FORM_VALUE:
      return {
        firstName: action.signupFormValue.firstName,
        lastName: action.signupFormValue.lastName,
        email: action.signupFormValue.email,
        confirmEmail: action.signupFormValue.confirmEmail,
        voucherCode: action.signupFormValue.voucherCode,
        birthYear: action.signupFormValue.birthYear,
        gender: action.signupFormValue.gender
      }
    case ActionTypes.INIT_SIGNUP_FORM_VALUE:
      return initialState
    default:
      return state
  }
}

export default signupFormValue
