
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Input from './input'
import styles from './password-input.css'
import eyeOpenIconWhite from '../../../images/neon/eye-open.svg'
import eyeCloseIconWhite from '../../../images/neon/eye-close.svg'
import eyeOpenIconDark from '../../../images/neon/eye-open-dark.svg'
import eyeCloseIconDark from '../../../images/neon/eye-close-dark.svg'

function PasswordInput({
  label,
  readonly,
  readonlyTextOverwrite,
  disabled,
  wrapperClassName,
  darkTheme,
  ...props
}) {
  const [shouldShowPassword, setShouldShowPassword] = useState(false)
  const inputType = shouldShowPassword ? 'text' : 'password'
  const togglePasswordVisibility = () => setShouldShowPassword(previousValue => !previousValue)
  // Ensure that the eye icon remains visible, color should contrast with the input background color
  const eyeOpenIcon = darkTheme ? eyeOpenIconWhite : eyeOpenIconDark
  const eyeCloseIcon = darkTheme ? eyeCloseIconWhite : eyeCloseIconDark

  return (
    <div className={classNames(styles.showPasswordWrapper, wrapperClassName, darkTheme && styles.showPasswordWrapperInLoginModal)}>
      <Input
        {...props}
        label={label}
        type={inputType}
        disabled={disabled}
        readonly={readonly}
        readonlyTextOverwrite={readonlyTextOverwrite}
        checkClass={styles.checkPassword}
        exclamationClass={classNames({
          [styles.exclamationPassword]: true,
          [styles.noLabel]: !label
        })}
      />
      {
        !readonly && !disabled && (
          <div
            className={classNames({
              [styles.showPassword]: true,
              [styles.noLabel]: !label
            })}
            onClick={togglePasswordVisibility}
          >
            <img
              src={shouldShowPassword ? eyeOpenIcon : eyeCloseIcon}
              alt="eyeIcon"
            />
          </div>
        )
      }
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  readonlyTextOverwrite: PropTypes.string,
  wrapperClassName: PropTypes.string,
  darkTheme: PropTypes.bool
}

PasswordInput.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  readonly: false,
  readonlyTextOverwrite: '',
  wrapperClassName: '',
  darkTheme: false
}

export default PasswordInput
