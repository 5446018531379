import { usePlanChangeFlag } from '../../../hooks/usePlanChange'

/**
 * Get plan info by id, sku, type and so on.
 */
export const usePlanDetails = () => {
  const getPlanDetails = usePlanChangeFlag()
  const plans = getPlanDetails ? [getPlanDetails.basic, getPlanDetails.standard, getPlanDetails.annual] : []

  const getPlanDetailById = (id) => {
    return plans.find(plan => plan.id === id) || ''
  }

  /**
   * @param {string} title - example: 'Basic', 'Standard', 'Annual'
   */
  const getPlanDetailByTitle = (title) => {
    return plans.find(plan => plan.title.toLowerCase() === title?.toLowerCase()) || ''
  }

  const getPlanTitleBySku = (sku) => {
    return plans.find(item => item.sku === sku)?.title || ''
  }

  /**
   * @param {string} type - example: 'BASIC', 'STANDARD', 'ANNUAL'
   */
  const getPlanSkuByType = (type) => {
    return plans.find(item => item.type === type)?.sku || ''
  }

  /**
   * @returns {string} DisplayName - example: 'Basic with Ads', 'Standard', 'Annual'
   */
  const getPlanDisplayNameByTitle = (title) => {
    return plans.find(item => item.title === title)?.label || ''
  }

  return {
    getPlanDetailById,
    getPlanDetailByTitle,
    getPlanTitleBySku,
    getPlanSkuByType,
    getPlanDisplayNameByTitle
  }
}
