/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Background } from 'react-imgix'
import { withApollo } from 'react-apollo'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import CtaBox from './cta-box'
import { IMGIX_CROP_OPTIONS } from '../../../constants'
import { useContentKeyartReplacement } from '../../../hooks/use-content-keyart-replacement'

import rentalBadge from '../../../../images/neon/rental-badge.png'
import styles from './slide.css'
import { checkIsAuthenticated } from '../../../lib/auth'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { getConfigValue } from '../../../utils/getConfigValue'
import { appConfigPropType } from '../../player/utils/prop-types'

function HotspotGuest({
  slide,
  client,
  className,
  appConfig
}) {
  const {
    image, copyright, croppingOption, ...ctaContent
  } = slide
  const background = useContentKeyartReplacement(client, image)
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isAuthenticated = session && checkIsAuthenticated(mapState)
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual
  const hotspotImage = getConfigValue(appConfig?.values, 'GUEST_HOME_HOTSPOT_IMAGE')

  const location = useLocation()
  // Override background ingredient color on guest home page
  const isChangeBg = !isAuthenticated && location.pathname === '/'
  const isShowingDiscount = showBasicDiscount || showStandardDiscount || showAnnualDiscount

  return (
    <div
      className={classnames(className, styles.slideWrapper, {
        [styles.guestWrapper]: isChangeBg && !isShowingDiscount,
        [styles.guestWrapperForDiscount]: isChangeBg && isShowingDiscount
      })}
      key="hotspot"
      data-e2e="hotspot-slide"
    >
      {slide.isRental && (
        <img
          className={styles.rentalBadge}
          src={rentalBadge}
          alt="Rental Content"
        />
      )}

      <div className={styles.imageWrapper}>
        { hotspotImage && (
          <Background
            className={classnames(styles.image, isChangeBg && styles.imageInCenter)}
            src={isChangeBg ? hotspotImage : background}
            type="img"
            imgixParams={{
              crop: croppingOption || IMGIX_CROP_OPTIONS.HOTSPOT,
              fit: 'min',
              auto: 'compress'
            }}
          />
        )}
      </div>

      <div className={classnames(styles.ctaWrapper, isShowingDiscount && styles.ctaWrapperForDiscount)}>
        <CtaBox content={ctaContent} isChangeBg={isChangeBg} />
      </div>

      <div className={styles.copyright}>{copyright}</div>
    </div>
  )
}

HotspotGuest.propTypes = {
  slide: PropTypes.oneOfType([PropTypes.object]).isRequired,
  className: PropTypes.string,
  appConfig: appConfigPropType.isRequired
}

HotspotGuest.defaultProps = {
  className: ''
}

export default withApollo(HotspotGuest)
