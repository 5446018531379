import { history } from '../store'

import ActionTypes from './types'
import { LOADING_OVERLAY } from '../constants'
import { stopYouboraEventsOnTimeout } from '../lib/player'

function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

export const login = makeActionCreator(ActionTypes.USER_LOGIN, 'login', 'rememberMe')
export const logout = makeActionCreator(ActionTypes.USER_LOGOUT)
export const updateAuthToken = makeActionCreator(ActionTypes.UPDATE_AUTH_TOKEN, 'token')
export const updateAuthTokenAndDefaultTheme = makeActionCreator(ActionTypes.UPDATE_AUTH_TOKEN_AND_THEME, 'token', 'theme')

export const saveHasSeenRenewalPrompt = (accountId, hasSeenRenewalPrompt = true) => ({
  type: ActionTypes.SAVE_HAS_SEEN_RENEWAL_PROMPT,
  accountId,
  hasSeenRenewalPrompt
})
export const saveHasSeenRepairPrompt = (accountId, hasSeenRepairPrompt = true) => ({
  type: ActionTypes.SAVE_HAS_SEEN_REPAIR_PROMPT,
  accountId,
  hasSeenRepairPrompt
})

export const saveIntroductionVersion =
  makeActionCreator(ActionTypes.SAVE_INTRODUCTION_VERSION, 'accountId', 'version')

// Video Player Actions
export const selectDataSaverOption = makeActionCreator(ActionTypes.SELECT_DATA_SAVER_OPTION, 'dataSaverSelection', 'accountId')
export const setVolume = makeActionCreator(ActionTypes.SET_VOLUME, 'volume')
export const setMuted = makeActionCreator(ActionTypes.SET_MUTED, 'muted')
export const setStartPlayingTimestamp = makeActionCreator(ActionTypes.SET_START_PLAYING_TIMESTAMP, 'startPlayingTimestamp')

// Navigation Actions
export const toggleNavigationVisibility = makeActionCreator(ActionTypes.NAVIGATION_TOGGLE_VISIBILITY, 'visibility')
export const setNavigationVisibility = makeActionCreator(ActionTypes.SET_NAVIGATION_VISIBILITY, 'visibility')
export const setSearchIsClicked = makeActionCreator(ActionTypes.SET_SEARCH_IS_CLICKED, 'isClicked')
export const setTrailerLaunchedFromComponent = makeActionCreator(ActionTypes.SET_TRAILER_LAUNCHED_FROM_COMPONENT, 'trailerModalVisibility')

// Account Settings Actions
export const toggleAccountNavVisibility = makeActionCreator(ActionTypes.ACCOUNT_NAVIGATION_TOGGLE_VISIBILITY, 'visibility', 'navComponentWidth')
export const setAccountNavVisibility = makeActionCreator(ActionTypes.SET_ACCOUNT_NAVIGATION_VISIBILITY, 'visibility', 'navComponentWidth')

// Account Password Timestamp
export const setAccountPasswordTimestamp = makeActionCreator(ActionTypes.SET_ACCOUNT_PASSWORD_TIMESTAMP, 'passwordTimestamp', 'accountId')

// Loading Overlay Actions
export const startLoadingOverlay = makeActionCreator(ActionTypes.LOADING_OVERLAY_ENABLE, 'key', 'operation')
export const successLoadingOverlay = makeActionCreator(ActionTypes.LOADING_OVERLAY_SUCCESS, 'key', 'operation')
export const failureLoadingOverlay = makeActionCreator(ActionTypes.LOADING_OVERLAY_ERROR, 'key', 'operation')
export const timeoutLoadingOverlay = makeActionCreator(ActionTypes.LOADING_OVERLAY_TIMEOUT, 'key', 'operation')
export const disableLoadingOverlay = makeActionCreator(ActionTypes.LOADING_OVERLAY_DISABLE, 'key')

let triggerTimeoutHandlerTimeoutId

const triggerTimeoutHandler = (key, operation, dispatch) => {
  triggerTimeoutHandlerTimeoutId = setTimeout(() => {
    // Since this timeout may pops up during a playback,
    // will make sure that Youbora fires an error and stops
    stopYouboraEventsOnTimeout(history)
    dispatch(timeoutLoadingOverlay(key, operation))
    setTimeout(() => {
      history.goBack()
    }, LOADING_OVERLAY.errorMsgTimeout)
  }, LOADING_OVERLAY.processTimeout)
}

export function endLoadingOverlay(key, operation, options) {
  const error = options && options.error
  const timeoutInterval = options && options.timeout

  return function endLoadingOverlayThunk(dispatch) {
    const timeout = setTimeout(() => {
      dispatch(disableLoadingOverlay(key, operation))
      clearTimeout(timeout)
    }, timeoutInterval || LOADING_OVERLAY.successTimeout)

    if (error) {
      dispatch(failureLoadingOverlay(key, operation))
      clearTimeout(triggerTimeoutHandlerTimeoutId)
    } else {
      dispatch(successLoadingOverlay(key, operation))
      clearTimeout(triggerTimeoutHandlerTimeoutId)
    }
  }
}

export function enableLoadingOverlay(key, operation) {
  return function enableLoadingOverlayThunk(dispatch) {
    dispatch(startLoadingOverlay(key, operation))
    triggerTimeoutHandler(key, operation, dispatch)
  }
}

// error dialog
export const triggerErrorDialog = makeActionCreator(ActionTypes.GENERIC_ERROR_MSG_SHOW, 'error')
export const dismissErrorDialog = makeActionCreator(ActionTypes.GENERIC_ERROR_MSG_DISMISS)

export function displayErrorDialog(key, operation) {
  return function displayErrorDialogThunk(dispatch) {
    dispatch([
      disableLoadingOverlay(key, operation),
      triggerErrorDialog(key, operation)
    ])
  }
}

// PIN
export const requirePin = makeActionCreator(ActionTypes.PIN_REQUIRE, 'onValidPin')
export const dismissPin = makeActionCreator(ActionTypes.PIN_DISMISS)
export const validatePin = makeActionCreator(ActionTypes.PIN_VALIDATE)

// Upgrade Popup
export const showPlanUpgradePopup = makeActionCreator(ActionTypes.PLAN_UPGRADE_POPUP_SHOW)
export const dismissPlanUpgradePopup = makeActionCreator(ActionTypes.PLAN_UPGRADE_POPUP_DISMISS)
export const confirmPlanUpgradePopup = makeActionCreator(ActionTypes.PLAN_UPGRADE_POPUP_CONFIRM)

// Themes
export const setTheme = makeActionCreator(ActionTypes.THEME_SET, 'theme')

// Service Messages
export const dismissServiceMessage = makeActionCreator(ActionTypes.SERVICE_MESSAGES_DISMISS, 'serviceMessage')

// Maintenance Mode
export const enableMaintenanceMode = makeActionCreator(ActionTypes.ENABLE_MAINTENANCE_MODE, 'message')
export const disableMaintenanceMode = makeActionCreator(ActionTypes.DISABLE_MAINTENANCE_MODE)
export const updateMaintenanceMessage = makeActionCreator(ActionTypes.UPDATE_MAINTENANCE_MESSAGE, 'message')

// Cast Sender Status
export const castSenderConnected = makeActionCreator(ActionTypes.CAST_SENDER_CONNECT)
export const castSenderDisconnected = makeActionCreator(ActionTypes.CAST_SENDER_DISCONNECT)

// App
export const setPlayerIsInView = makeActionCreator(ActionTypes.SET_PLAYER_IS_IN_VIEW, 'playerIsInView', 'theme')

// Manage Subscription Options
export const setManageSubsOptions = makeActionCreator(ActionTypes.SET_MANAGE_SUBS_OPTIONS, 'manageSubsOptions')

// Current Subscription Name
export const setCurrentSubscriptionName = makeActionCreator(ActionTypes.SET_CURRENT_SUBSCRIPTION_NAME, 'currentSubscriptionName')

// Set Voucher Validate Error and Type
export const setVoucherValidateError = makeActionCreator(ActionTypes.SET_VOUCHER_VALIDATE_ERROR, 'voucherValidateError')
export const setVoucherValidateType = makeActionCreator(ActionTypes.SET_VOUCHER_VALIDATE_TYPE, 'voucherValidateType')

// Set sign up form
export const setSignupFormValue = makeActionCreator(ActionTypes.SET_SIGNUP_FORM_VALUE, 'signupFormValue')
export const initSignupFormValue = makeActionCreator(ActionTypes.INIT_SIGNUP_FORM_VALUE, 'signupFormValue')

export { switchProfile, switchProfileWithPin, logoutAndRedirectTo } from './profile'
