import { createUseStyles } from 'react-jss'

export const commonStyles = theme => ({
  primaryButton: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 350,
    color: theme.black,
    background: theme.neonGreen,
    border: '1px solid  transparent',
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      background: theme.neonGreenLight
    },

    '&:disabled': {
      background: theme.neonGreenLight
    }
  },

  secondaryButton: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.white,
    background: 'transparent',
    border: `1px solid ${theme.white}`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      color: theme.neonGreen,
      borderColor: theme.neonGreen
    }
  },

  outlineButton: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.neonGreen,
    background: 'transparent',
    border: `1px solid ${theme.neonGreen}`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      color: theme.black,
      backgroundColor: theme.neonGreen
    }
  },

  outlineButtonPink: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.neonPink,
    background: 'transparent',
    border: `1px solid ${theme.neonPink}`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      color: theme.black,
      backgroundColor: theme.neonPink
    }
  },

  input: {
    display: 'block',
    width: '100%',
    fontSize: 15,
    lineHeight: 1.07,
    padding: 12,
    color: theme.white,
    borderRadius: 4,
    background: theme.field,
    outline: 'none',
    border: '1px solid transparent',
    transition: 'all 0.125s',

    '&:focus': {
      background: theme.fieldHighlight,
      border: '1px solid #979797',
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.35), 0 2px 4px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(0, 0, 0, 0.5)'
    }
  },

  paymentMethodCard: {
    border: `0px solid ${theme.neonGreen}`,
    borderRadius: 8,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',

    [theme.media.tablet]: {
      padding: [50, 20],
      width: 240
    },
    [theme.media.desktop]: {
      minHeight: 160,
      height: 'calc(100% - 88px)',
      maxWidth: 442,
      marginTop: 20,
      marginBottom: 20
    },

    background: 'linear-gradient(90deg, #252525 -0.11%, rgba(37, 37, 37, 0) 99.89%)'
  }
})

export const useCommonStyles = createUseStyles(commonStyles)
