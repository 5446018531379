import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Form from '../../forms/layout-form'
import PasswordInput from '../../forms/password-input'
import Button from '../../forms/button'

import styles from './enter-account-password.css'

const PasswordInputForm = ({
  handleSubmit,
  handleCancel,
  handleBlur,
  handleChange,
  handleIForgotMyPassword,
  isSubmitting,
  value,
  passwordValidated
}) => {
  const errorMsg = "Sorry! We don't recognise this password."

  return (
    <Form
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      formClassName={styles.form}
    >
      <div>
        <PasswordInput
          label="Password"
          name="password"
          value={value}
          min={6}
          max={21}
          onChange={handleChange}
          onBlur={handleBlur}
          warning={passwordValidated === false ? errorMsg : ''}
          isValid={passwordValidated}
          inputClass={classNames({
            [styles.validInput]: passwordValidated
          })}
        />
        {
          passwordValidated &&
          <div className={styles.thanks}>Thanks!</div>
        }
        <div className={styles.iForgotMyPassword}>
          <span onClick={handleIForgotMyPassword}>
            I forgot my password
          </span>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="OK"
          disabled={isSubmitting}
          data-e2e="OK-button"
        />
        <Button
          data-e2e="cancel-button"
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="Cancel"
          disabled={isSubmitting}
          secondary
          onClick={handleCancel}
        />
      </div>
    </Form>
  )
}

PasswordInputForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIForgotMyPassword: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  value: PropTypes.string,
  passwordValidated: PropTypes.bool
}

PasswordInputForm.defaultProps = {
  value: '',
  passwordValidated: null
}

export default PasswordInputForm
