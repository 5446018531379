import React from 'react'
import PropTypes from 'prop-types'

import { useSelectPlan } from '../../hooks/use-select-plan'
import { useStyles } from './plan-selection-standard-view-new.styles'
import RentMovieSection from '../rent-movie-view/rent-movie-section'
import SubscriptionPricesView from '../subscription-prices-view/subscription-prices-view'
import SubscriptionContentsView from '../subscription-contents-view/subscription-contents-view'
import SubscriptionCardContent from '../subscription-card-content/subscription-card-content'
import { planDetailsForAvod } from '../../../shared/subscription-constants'

const PlanSelectionStandardViewNew = props => {
  const styles = useStyles()
  const { showRentMovies, selectToNext } = props
  const { activeSubscription, updateActiveSubscription } = useSelectPlan()

  return (
    <div className={styles.standardScreenContainer}>
      <div className={styles.tableRowContainer}>
        <div className={styles.tableRowTitleFrame}>
          <div className={styles.tableRowTitle}>{planDetailsForAvod.componentTitle}</div>
          <div className={styles.paymentMethodCard}>
            <SubscriptionCardContent />
          </div>
        </div>

        <div className={styles.tableRowContent}>
          <SubscriptionPricesView activeSubscription={activeSubscription} updateActiveSubscription={updateActiveSubscription} />
        </div>
      </div>

      <SubscriptionContentsView
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        selectToNext={selectToNext}
        showRentMovies={showRentMovies}
        addSubscriptionFromSignup
      />

      { showRentMovies && <RentMovieSection selectToNext={selectToNext} /> }
    </div>
  )
}

PlanSelectionStandardViewNew.propTypes = {
  showRentMovies: PropTypes.bool.isRequired,
  selectToNext: PropTypes.func
}

PlanSelectionStandardViewNew.defaultProps = {
  showRentMovies: true
}

export default PlanSelectionStandardViewNew
