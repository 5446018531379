import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import qs from 'query-string'

import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, STORAGE_KEY_SELECTED_PLAN_ON_GUEST } from '../../../storage-keys'
import { links, signupSequence } from '../../shared/subscription-constants'
import { usePlanDetails } from '../../shared/hooks/use-plan-details'

/**
 * Responsible for determining whether to render the sign up abandoned
 * banner on the site and handle user interaction with the banner.
 */

const DEFAULT_PLAN = 'Standard'

export const useSelectPlan = (currentSubscription) => {
  const history = useHistory()
  const location = useLocation()
  const { voucher } = qs.parse(location.search)
  const { getPlanTitleBySku, getPlanDetailByTitle } = usePlanDetails()

  // Get the pre selected plan if it exists when users access the select plan table
  const prePlan = sessionStorage.getItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST)
  const planFromGuestHomePage = prePlan && getPlanDetailByTitle(prePlan)?.title

  // Get the voucher applicable plans if voucher exists
  const voucherApplicablePlanSkus = sessionStorage.getItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)?.split(',')
  const voucherApplicablePlanNames = []
  voucher && voucherApplicablePlanSkus?.forEach(sku => {
    voucherApplicablePlanNames.push(getPlanTitleBySku(sku))
  })

  // Set the first voucher applicable plan as default active plan or
  // if planFromGuestHomePage is one of the voucher applicable plans
  // then set planFromGuestHomePage as default active plan
  let voucherRelatedPlan = null
  if (voucherApplicablePlanNames.includes(planFromGuestHomePage)) {
    voucherRelatedPlan = planFromGuestHomePage
  } else {
    voucherRelatedPlan = voucherApplicablePlanNames[0]
  }
  const mapState = useSelector(state => state)
  const { voucherValidateType } = mapState
  const [activeSubscription, setActiveSubscription] = useState(currentSubscription || voucherValidateType || voucherRelatedPlan || planFromGuestHomePage || DEFAULT_PLAN)

  const selectToNext = (type) => {
    // assign state to location to start sign up flow
    const signupState = { signup: signupSequence.planSelection }
    history.push({
      pathname: links.payment,
      search: qs.stringify({
        ...qs.parse(location.search),
        plan: type
      })
    }, signupState)

    // Remove the pre selected plan when users go to payment page.
    sessionStorage.removeItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST)
  }

  const updateActiveSubscription = (data) => {
    setActiveSubscription(data)
  }

  return {
    activeSubscription,
    updateActiveSubscription,
    selectToNext
  }
}
