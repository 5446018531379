import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'

import FeatureFlagQuery from '../../graphql/queries/feature-flags.gql'

/**
 * Fetches the value list of feature flags from the server
 * Can be supplied with a default value to use while resolving the feature flag
 *
 * @param {string[]} featureNames The names of the feature flag
 * @param {*} defaultValue Dictate the default value to use while the flag is loading (false by default)
 * @returns {Object} { featureName1: Boolean, featureName2: Boolean }
 */
const useFeatureFlags = (featureNames, defaultValue = false) => {
  const defaultState = featureNames.reduce(
    (result, featureName) => Object.assign(result, { [featureName]: defaultValue }),
    {}
  )

  const [flag, setFlag] = useState(defaultState)
  const { data, loading, error } = useQuery(FeatureFlagQuery, {
    variables: {
      featureNames: [...featureNames]
    }
  })

  useEffect(() => {
    if (loading) return

    if (error) {
      console.error(
        `useFeatureFlags FeatureFlagQuery error`
      )
      return
    }

    const convertedResponse = data.featureflags.reduce(
      (result, featureflag) => Object.assign(result, { [featureflag.featureName]: featureflag.isEnabled }),
      {}
    )
    setFlag(convertedResponse)
  }, [data, loading, error])

  return flag
}

export { useFeatureFlags }
