import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { sanitizeDom } from '../../../utils'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import NavLinkWithQuery from '../../shared/nav-link-with-query'
import styles from './cta-box.css'
import EmailBox from './email-box'

function CtaBox({ content, isChangeBg }) {
  const {
    line1, subLine1, line2, line2OnGuestHomePage, line3, cta, isRental, isGuestHome, subLine1ForBasicDiscount, subLine1ForStandardDiscount, subLine1ForAnnualDiscount
  } = content

  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual
  const isShowingDiscount = showBasicDiscount || showStandardDiscount || showAnnualDiscount
  const line2OnGuestHomePageText = isShowingDiscount ? ' ' : line2OnGuestHomePage
  const line2Message = isChangeBg ? line2OnGuestHomePageText : line2

  return (
    <div className={classNames(styles.wrapper, isChangeBg && styles.wrapperInCenter)}>
      <div className={classNames(styles.title, isChangeBg && styles.largeTitle)}>{line1}</div>

      {!isShowingDiscount && subLine1 && isChangeBg && (
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{ __html: sanitizeDom(subLine1) }}
        />
      )}

      {showBasicDiscount && subLine1ForBasicDiscount && isChangeBg && (
        <div
          className={styles.subTitleForDiscount}
          dangerouslySetInnerHTML={{ __html: subLine1ForBasicDiscount }}
        />
      )}

      {showStandardDiscount && subLine1ForStandardDiscount && isChangeBg && (
        <div
          className={styles.subTitleForDiscount}
          dangerouslySetInnerHTML={{ __html: subLine1ForStandardDiscount }}
        />
      )}

      {showAnnualDiscount && subLine1ForAnnualDiscount && isChangeBg && (
        <div
          className={styles.subTitleForDiscount}
          dangerouslySetInnerHTML={{ __html: subLine1ForAnnualDiscount }}
        />
      )}

      {line2Message && (
        <div
          className={classNames(styles.synopsis, isChangeBg && styles.description)}
          dangerouslySetInnerHTML={{ __html: sanitizeDom(line2Message) }}
        />
      )}

      {isGuestHome ? (
        <EmailBox />
      ) : (
        <div className={styles.buttonGroup}>
          {cta.text && (
            <NavLinkWithQuery
              data-e2e={
                isRental
                  ? 'cta-box-rental-slide-button'
                  : 'cta-box-slide-start-watching-button'
              }
              className={classNames(styles.button, {
                [styles.isRental]: isRental
              })}
              to={cta.url}
            >
              {cta.text}
            </NavLinkWithQuery>
          )}
          <div className={styles.wordsUnderButton}>{line3}</div>
        </div>
      )}
    </div>
  )
}

CtaBox.propTypes = {
  content: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    subLine1: PropTypes.string,
    subLine1ForBasicDiscount: PropTypes.string,
    subLine1ForStandardDiscount: PropTypes.string,
    subLine1ForAnnualDiscount: PropTypes.string,
    line2: PropTypes.string.isRequired,
    line2OnGuestHomePage: PropTypes.string,
    line3: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    isRental: PropTypes.bool,
    isGuestHome: PropTypes.bool
  }).isRequired,
  isChangeBg: PropTypes.bool.isRequired
}

export default CtaBox
