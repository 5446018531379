import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  tableRowColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },

  activeSubscription: {
    color: theme.neonGreen
  },

  button: {
    zIndex: theme.zIndexCardBadge,
    position: 'relative',
    padding: '10px',
    height: 60,
    width: '100%',
    marginTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 22,
    [theme.media.smallMobile]: {
      fontSize: 16
    }
  },

  hoverButton: {
    color: theme.black,
    backgroundColor: theme.neonGreen,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '99%',
      left: 0,
      right: 0,
      width: 0,
      height: 0,
      margin: '0 auto',
      borderTop: ['solid', 12, theme.neonGreen],
      borderLeft: 'solid 18px transparent',
      borderRight: 'solid 18px transparent'
    }
  },

  priceContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  priceContentDisabled: {
    color: '#a0a0a0',
    width: '100%',
    backgroundColor: theme.greyHighlight,
    height: 200,
    [theme.media.mobile]: {
      height: 155
    },
    [theme.media.smallMobile]: {
      height: 120
    },
    marginTop: -20
  },

  priceAmountDisabled: {
    paddingTop: 20
  },

  priceContentPeriod: {
    color: '#a0a0a0',
    fontSize: 20,
    textAlign: 'center',
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  priceAmount: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'GothamRounded'
  },

  priceAmountBeforeDot: {
    fontSize: 56,
    lineHeight: 1,
    fontWeight: 400,
    [theme.media.mobile]: {
      fontSize: 44
    },
    [theme.media.smallMobile]: {
      fontSize: 34
    }
  },

  priceAmountAfterDot: {
    fontSize: 32,
    [theme.media.mobile]: {
      fontSize: 26
    },
    [theme.media.smallMobile]: {
      fontSize: 20
    }
  },

  currency: {
    fontSize: 32,
    lineHeight: 1.45,
    fontWeight: 400,
    [theme.media.mobile]: {
      fontSize: 26
    },
    [theme.media.smallMobile]: {
      fontSize: 20
    }
  },

  priceContentSubTitle: {
    color: '#a0a0a0',
    fontSize: 20,
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  planTitle: {
    height: 70,
    fontSize: 19,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.white,
    [theme.media.largeDesktop]: {
      height: 50
    },
    [theme.media.mobile]: {
      fontSize: 16,
      height: 60
    },
    [theme.media.smallMobile]: {
      fontSize: 12,
      height: 50
    }
  },

  planFooterTitle: {
    fontSize: 16,
    [theme.media.mobile]: {
      fontSize: 12,
      height: 40
    },
    [theme.media.smallMobile]: {
      fontSize: 8,
      height: 30
    }
  },

  discountBeforePrice: {
    color: theme.neonGreen,
    fontSize: 20,
    textDecoration: 'line-through 3px',
    whiteSpace: 'break-spaces',
    marginBottom: 10
  },

  discountEndDate: {
    fontSize: 16,
    fontWeight: 325,
    lineHeight: '20.8px',
    textAlign: 'center',
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  discountTermsAndConditions: {
    fontSize: 16,
    fontWeight: 325,
    lineHeight: '20.8px',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    color: 'white',
    paddingBottom: 30,
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  discountLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 153,
    height: 28,
    padding: '6px 10px 6px 10px',
    borderRadius: '8px 0px 8px 0px',
    backgroundColor: theme.neonGreen,
    margin: '15px 0',
    [theme.media.smallMobile]: {
      width: 100,
      height: 22,
      padding: '3px 4px 3px 4px'
    }
  },

  discountLabelText: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'GothamRounded',
    textAlign: 'center',
    [theme.media.smallMobile]: {
      fontSize: 11,
      fontWeight: 500
    }
  }
}))
