const imageBase = 'https://lightbox-prod.imgix.net/images/static'
const imageUpdateBase = 'https://lightbox-prod.imgix.net/images/uploads'
const hotspotImageBase = 'https://lightbox-prod.imgix.net/images/static'
const ctaText = 'Start Watching'
const ctaUrl = '/signup/account'

const moviesP1 = {
  image: `${hotspotImageBase}/2024/06/Barbie-2023-CC2.jpg`,
  line1: 'Barbie',
  line2: 'She’s everything.<br />He’s just Ken.',
  line3: '',
  copyright: '© 2023 Warner Bros. Entertainment Inc. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const moviesP2 = {
  image: `${hotspotImageBase}/2024/06/Meg-2-The-Trench-2023-CC2.jpg`,
  line1: 'Meg 2: The Trench',
  line2: 'Action-packed thriller starring Jason Statham',
  line3: '',
  copyright: '© 2023 Warner Bros. Entertainment Inc. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const moviesP3 = {
  image: `${hotspotImageBase}/2024/06/Am-I-OK-2024-Still.jpg`,
  line1: 'Am I Ok?',
  line2: 'Heartfelt comedy-drama about self-discovery,<br />starring Dakota Johnson',
  line3: '',
  copyright: '© 2024 WarnerMedia Direct, LLC. All rights reserved. MAX and related elements are property of Home Box Office, Inc.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const moviesP4 = {
  image: `${hotspotImageBase}/2024/06/The-Equalizer-3-2023-Still.jpg`,
  line1: 'The Equalizer 3',
  line2: 'Denzel Washington returns as Robert McCall',
  line3: '',
  copyright: '© 2023 Columbia Pictures Industries, Inc., TSG Entertainment II LLC, Eagle Pictures SpA and Lantern Entertainment Pictures, LLC. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const moviesP5 = {
  image: `${hotspotImageBase}/2024/06/Blue-Beetle-2023-CC2.jpg`,
  line1: 'Blue Beetle',
  line2: 'He is a Superhero,<br />whether he likes it or not',
  line3: '',
  copyright: '© 2023 Warner Bros. Entertainment Inc. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tvP1 = {
  image: `${hotspotImageBase}/2024/06/The-Tattooist-of-Auschwitz-S1-E5.JPG`,
  line1: 'The Tattooist of Auschwitz',
  line2: 'Based on the novel by Kiwi author Heather Morris',
  line3: '',
  copyright: '© TTOA Ltd 2024',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tvP2 = {
  image: `${hotspotImageBase}/2024/06/House-of-the-Dragon-S2-E1.jpg`,
  line1: 'House of the Dragon',
  line2: 'Raise your banners.<br />Sides must be chosen',
  line3: '',
  copyright: '© 2024 Home Box Office, Inc. All rights reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tvP3 = {
  image: `${hotspotImageBase}/2024/06/The-Good-Doctor-S6-E13.jpg`,
  line1: 'The Good Doctor',
  line2: 'Watch all seasons of the award-winning medical drama',
  line3: '',
  copyright: '© 2024 Sony Pictures Entertainment. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tvP4 = {
  image: `${hotspotImageBase}/2024/06/On-the-Roam-S1-E6.jpg`,
  line1: 'On the Roam',
  line2: 'Jason Momoa journeys to meet American innovators',
  line3: '',
  copyright: '© 2024 WarnerMedia Direct, LLC. All Rights Reserved. HBO Max™ is used under license.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tvP5 = {
  image: `${hotspotImageBase}/2024/06/War-of-the-Worlds-S2-E4-EPa.jpg`,
  line1: 'War of the Worlds',
  line2: 'Daisy Edgar-Jones stars in the bold reimagining of H.G.<br />Wells classic novel',
  line3: '',
  copyright: '© 2021 URBAN MYTH FILMS (WOTW) LIMITED',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const kidsP1 = {
  image: `${imageBase}/guest-hotspot-kids-1.14-5-2018.jpg`,
  line1: 'Paw Patrol',
  line2: 'No job is too big, <br /> no pup is too small!',
  line3: '',
  copyright: '© 2018 Spin Master PAW Productions Inc. © 2018 Viacom International Inc. All Rights Reserved',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const kidsP2 = {
  image: `${imageUpdateBase}/1602456288401-bluey-s1-cc2.jpg`,
  croppingOption: 'edge',
  line1: 'Bluey',
  line2: 'The adventures of the lovable Blue Heeler family',
  line3: '',
  copyright: 'Ludo Studio 2019',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const kidsP3 = {
  image: `${imageUpdateBase}/1687912267899-blaze-and-the-monster-machines-s6_hploggedout.jpg`,
  line1: 'Blaze and The Monster Machines',
  line2: 'Race into adventure<br />Leeeet’s Blaaaaze!',
  line3: '',
  copyright: '© 2023 Viacom International Inc. All Rights Reserved.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const kidsP4 = {
  image: `${imageUpdateBase}/1523919014083-spongebobbs.jpg`,
  croppingOption: 'edge',
  line1: 'SpongeBob SquarePants',
  line2: 'Who lives in a pineapple<br />under the sea...?',
  line3: '',
  copyright: '© 2020 Viacom International Inc. Created by Stephen Hillenburg.',
  cta: {
    text: ctaText,
    url: ctaUrl
  }
}

const tv = {
  slides: [
    tvP1,
    tvP2,
    tvP3,
    tvP4,
    tvP5
  ]
}

const movies = {
  slides: [
    moviesP1,
    moviesP2,
    moviesP3,
    moviesP4,
    moviesP5
  ]
}

const kids = {
  slides: [
    kidsP1,
    kidsP2,
    kidsP3,
    kidsP4
  ]
}

const homeHero = {
  image: 'https://lightbox-prod.imgix.net/images/uploads/breadth-of-content-image_Jul_2022.jpg',
  line1: 'Get it on Neon.',
  subLine1: `
  Huge range of TV shows & movies,
  <br />
  handpicked for Kiwis by Kiwis.`,
  line2: '',
  line2OnGuestHomePage: `<span>Choose your plan & start watching <span style="font-weight:410">from only $12.99 per month</span></span>`,
  line3: '',
  copyright: '',
  cta: {
    text: ctaText,
    url: ctaUrl
  },
  isGuestHome: true,
  subLine1ForBasicDiscount: `
  Limited Time Offer. Take off 50% for 3 months on a Neon Basic Plan. Offer ends 16 November 2024. <a target="_blank" href="/basic-plan-offer" style="color:white;text-decoration:underline;text-underline-offset:6px">T&C's apply</a>.`,
  subLine1ForStandardDiscount: `
  Limited Time Offer. Take off 50% for 3 months on a Neon Standard Plan. Offer ends 16 November 2024. <a target="_blank" href="/standard-plan-offer" style="color:white;text-decoration:underline;text-underline-offset:6px">T&C's apply</a>.`,
  subLine1ForAnnualDiscount: `
  Limited Time Offer. Take off 50% for 3 months on a Neon Annual Plan. Offer ends 16 November 2024. <a target="_blank" href="/annual-plan-offer" style="color:white;text-decoration:underline;text-underline-offset:6px">T&C's apply</a>.`
}

const home = {
  slides: [
    homeHero
  ]
}

export default {
  home,
  tv,
  movies,
  kids,
  about: home
}
