import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useStyles } from './subscription-price-section.styles'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import {
  basicPlanDetailsWithDiscount,
  standardPlanDetailsWithDiscount,
  annualPlanDetailsWithDiscount,
  planType
} from '../../../shared/subscription-constants'

const CURRENT_PLAN_TITLE = 'CURRENT PLAN'

const SubscriptionPriceSection = props => {
  const styles = useStyles()
  const {
    title, label, price, period, subTitle, activeSubscription, updateActiveSubscription, currentSubscription
  } = props
  const priceIndex = price.indexOf('.')
  const priceBeforeDot = price.substr(0, priceIndex)
  const priceAfterDot = price.substr(priceIndex)
  const getPlanDetails = usePlanChangeFlag()
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual

  const planHeader = () => {
    let content = null
    if (!currentSubscription && title?.toUpperCase() === planType.ANNUAL) {
      // Only show annual extra info in header if user do not have any subscription
      content = getPlanDetails?.annual.content
    } else if (currentSubscription === title) {
      content = CURRENT_PLAN_TITLE
    }
    return <div className={styles.planTitle}>{content}</div>
  }

  const priceSection = () => {
    let priceDetail = {
      priceBeforeDot,
      priceAfterDot,
      period,
      subTitle
    }

    if (showBasicDiscount && title?.toUpperCase() === planType.BASIC && !currentSubscription) {
      priceDetail = basicPlanDetailsWithDiscount
    }

    if (showStandardDiscount && title?.toUpperCase() === planType.STANDARD && !currentSubscription) {
      priceDetail = standardPlanDetailsWithDiscount
    }

    if (showAnnualDiscount && title?.toUpperCase() === planType.ANNUAL && !currentSubscription) {
      priceDetail = annualPlanDetailsWithDiscount
    }

    return (
      <div className={classNames(styles.priceContent, currentSubscription === title && styles.priceContentDisabled)}>
        <div className={classNames(styles.priceAmount, activeSubscription === title && styles.activeSubscription, currentSubscription === title && styles.priceAmountDisabled)}>
          <span className={styles.currency}>$</span>
          <span className={styles.priceAmountBeforeDot}>{priceDetail.priceBeforeDot}</span>
          <span className={styles.priceAmountAfterDot}>{priceDetail.priceAfterDot}</span>
        </div>
        {
          priceDetail.originalPrice &&
          (
            <span className={styles.discountBeforePrice}>
              &nbsp;$
              {priceDetail.originalPrice}
              &nbsp;
            </span>
          )
        }
        <span className={styles.priceContentPeriod}>{priceDetail.period}</span>
        {priceDetail.subTitle && <span className={styles.priceContentSubTitle}>{subTitle}</span>}
        {
          priceDetail.label &&
          (
            <div className={styles.discountLabel}>
              <p className={styles.discountLabelText}>
                {priceDetail.label}
              </p>
            </div>
          )
        }
        {priceDetail.endDate && <span className={styles.discountEndDate}>{priceDetail.endDate}</span>}
        {
          priceDetail.termsAndConditionsLink &&
          (
            <a
              className={styles.discountTermsAndConditions}
              href={priceDetail.termsAndConditionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              T&C&apos;s apply
            </a>
          )
        }
        {
          currentSubscription && title === getPlanDetails?.annual.title &&
          <div className={classNames(styles.planTitle, styles.planFooterTitle)}>{getPlanDetails?.annual.content}</div>
        }
      </div>
    )
  }

  return (
    <div className={styles.tableRowColumn} onClick={() => updateActiveSubscription(title)}>
      {planHeader()}

      <div
        className={classNames(styles.outlineButton, styles.button, activeSubscription === title && styles.hoverButton)}
        data-e2e={`plan-title-${title}`}
      >
        {label}
      </div>

      {priceSection()}
    </div>
  )
}

SubscriptionPriceSection.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  currentSubscription: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired
}

SubscriptionPriceSection.defaultProps = {
  title: '',
  price: '',
  subTitle: '',
  currentSubscription: '',
  period: '',
  label: '',
  activeSubscription: '',
  updateActiveSubscription: null
}

export default SubscriptionPriceSection
