// TODO: add local and session storage keys to this file in future tickets
/**
 * a single plan name get from guest home page
 * @example "BASIC"
 */
export const STORAGE_KEY_SELECTED_PLAN_ON_GUEST = 'neon:selected-plan-on-guest'

/**
 * a list of plan sku get from the applicablePlans in query VOUCHER_WITHOUT_SKU_QUERY
 * @example "LBPREMTV,LBBASICTV"
 */
export const STORAGE_KEY_VOUCHER_APPLICABLE_PLANS = 'neon:voucher-applicable-plans'

/**
 * email get from the emailBox in guest home page
 * it will be used to initialize the login modal and sign up form
 */
export const STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE = 'neon:email-on-guest-home-page'
